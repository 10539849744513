*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
  touch-action: manipulation;
  font-feature-settings: 'case' 1, 'rlig' 1, 'calt' 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  font-family: var(--font-sans);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text-primary);
}

body {
  position: relative;
  min-height: 100%;
  margin: 0;
}

div,
button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
div,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@media print {
  * { overflow: visible !important; }
}
