* {
    box-sizing: border-box;
}

.hidden {
    display: none;
}

.preview-wrapper .hide-preview {
	visibility: hidden;
}

.preview-wrapper .preview-editor {
	position: unset;
}

.preview-wrapper .preview-editor .exit-preview {
	display: inline-block;
}

.editor-area-wrappper {
	width: 100%;
	height: 100%;
    margin-right: 0;
}

.tote-editor-area .hide-preview {
	@media (max-width: 767px) {
		margin-bottom: 90px;
	}
}

.tote-editor-area .hide-preview+.hide-preview {
	@media (max-width: 767px) {
		bottom: 55px;
	}
}

div.close {
	-webkit-appearance: none;
	 -moz-appearance:   none;
	 appearance:        none;
}

img.size-600-800 {
	width: 175px;
	height: 118px;
}

img.size-400-600 {
	width: 175px;
	height: 118px;
}

img.size-400-400 {
	width: 230px;
	height: 160px;
}

#launcher {
	left: 0px !important;
}

#webWidget {
	left: 0px !important;
}