/* Color picker wrapper. */
.hex-color-picker .chrome-picker {
    @apply shadow-none !important;
}
.hex-color-picker .chrome-picker>div:first-child {
    @apply rounded-lg;
}
.hex-color-picker .chrome-picker>div:nth-child(2) {
    @apply px-0 pt-3 pb-0 !important;
}

/* Large canvas with color palette at the top. */
.hex-color-picker .chrome-picker>div:nth-child(1) {
    @apply h-[120px] rounded-sm !important;
}
.online-editor-color-picker .hex-color-picker .chrome-picker>div:nth-child(1) {
    @apply h-[290px] !important;
}

/* Selected color preview (hidden) */
.hex-color-picker>.chrome-picker>div:nth-child(2)>.flexbox-fix:nth-child(1)>div:first-child {
    @apply hidden !important;
}

/* Color selection slider and white pointer on it */
.hex-color-picker>.chrome-picker>div:nth-child(2)>.flexbox-fix:nth-child(1),
.hex-color-picker .hue-horizontal {
    @apply h-10 rounded-sm !important;
}

/* Handler on color selection slider */
.hex-color-picker .hue-horizontal>div>div {
    @apply w-[24px] h-[24px] rounded-md top-[1px] bg-transparent border-2 border-core-white -translate-x-5 !important;
}
.hex-color-picker .hue-horizontal>div {
    @apply bg-transparent !important;
}

/* Dropdown with different color types: HEX, RGB, HSL */
.hex-color-picker>.chrome-picker>div:nth-child(2)>.flexbox-fix:nth-child(2) {
    @apply pt-3 !important;
}
.hex-color-picker>.chrome-picker>div:nth-child(2)>.flexbox-fix:nth-child(2)>.flexbox-fix input,
.hex-color-picker>.chrome-picker>div:nth-child(2)>.flexbox-fix:nth-child(2)>.flexbox-fix label {
    @apply h-10 text-xl border-solid border-[1px] border-core-purple-10 shadow-none !important;
}
.hex-color-picker>.chrome-picker>div:nth-child(2)>.flexbox-fix:nth-child(2)>.flexbox-fix label {
    @apply mt-3 !important;
}

/* Dropdown toggle: SVG button with arrows */
.hex-color-picker>.chrome-picker>div:nth-child(2)>.flexbox-fix:nth-child(2)>div:not(.flexbox-fix) {
    @apply flex ml-3 items-center justify-center border-[1px] border-core-purple-10 !important;
}
.hex-color-picker>.chrome-picker>div:nth-child(2)>.flexbox-fix:nth-child(2)>div:not(.flexbox-fix) svg {
    @apply w-[32px] h-[55px] rounded-none !important;
}
.hex-color-picker>.chrome-picker>div:nth-child(2)>.flexbox-fix:nth-child(2)>div:not(.flexbox-fix) div {
    @apply m-0 !important;
}